import './App.css';
import axios from 'axios';
import React, {useState, useEffect} from 'react';

const IP = "http://143.110.190.20:8084";
const baseURL = "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-mainnet";
const priceURL = "https://api.coingecko.com/api/v3/simple/price?ids=the-graph&vs_currencies=usd";


async function getNetworkStats() {
    const query = `
{
  graphNetworks(first: 1) {
    totalTokensAllocated
    delegationRatio
  }
}
  `;
    const res = await axios.post(baseURL, {query});
    return parseFloat(((res.data.data.graphNetworks[0].totalTokensAllocated) / Math.pow(10, 18)).toFixed(2));
}

async function loadNetworkData(setTotalTokensAllocated) {
    const networkStats = await getNetworkStats();
    setTotalTokensAllocated(networkStats)
    console.log("Done now")

}


async function getIndexerStats(address) {
    const query = `
{
  indexer(id: "${address}") {
    indexingRewardCut
    queryFeeCut
    stakedTokens
    delegatedTokens
    queryFeesCollected
    rewardsEarned
  }
}
  `;
    const res = await axios.post(baseURL, {query});
    return res.data;
}

async function getPriceStats() {
    const res = await axios.get(priceURL);
    return res.data;
}

async function loadIndexerData(address) {
    const indexerStats = await getIndexerStats(address);
    return indexerStats.data.indexer;
}


async function getPriceInUsd() {
    const priceStats = await getPriceStats();
    return priceStats["the-graph"].usd;
}


function App() {

    const button = document.getElementById('button');

    const load = async () => {

            // button.addEventListener('click', () => {
                console.log('Calculating...');
                // show loader
        if (button)
                button.classList.add('loading');

                // set timeout
                setTimeout(computeResults, 5000);

            // });
    }

    const [totalTokensAllocated, setTotalTokensAllocated] = useState([]);


    useEffect(() => {
        // Runs ONCE after initial rendering
        loadNetworkData(setTotalTokensAllocated);

    }, []);

    const computeResults = async () => {


        console.log("loading");
        const addressVar = document.getElementById("address").value;
        console.log(addressVar)

        console.log("amount");
        console.log();
        if ((!addressVar)) {
            alert("Please enter an address");
            if (button)
                button.classList.remove('loading');

            document.getElementById("ownEventNonce").innerHTML = "#️⃣";

            document.getElementById("lagging_by").innerHTML = "🏃";
            document.getElementById("percentageHigherThanYourOwn").innerHTML = "⬆️";
            //return
            returnApp()
        }


        const indexerStats = await (loadIndexerData(addressVar));
        const response = await axios.get(`${IP}/peggo?lcd=https://umee-api.polkachu.com&orchAddress=${addressVar}`);
        let result = response.data
        console.log(result)

        // check if indexer stats is null
        if (!result
            || !result.ownEventNonce
        ) {
            alert("Please enter a correct Orchestrator Address");
            if (button)
                button.classList.remove('loading');

            document.getElementById("ownEventNonce").innerHTML = "#️⃣";
            document.getElementById("lagging_by").innerHTML = "🏃";
            document.getElementById("percentageHigherThanYourOwn").innerHTML = "⬆️";
            //return
            return returnApp()
        }


        document.getElementById("ownEventNonce").innerHTML = result.ownEventNonce + " #️⃣";

        document.getElementById("lagging_by").innerHTML = result.lagging_by + " 🏃";
        document.getElementById("percentageHigherThanYourOwn").innerHTML = result.percentageHigherThanYourOwn + " ⬆️";
        if (button)
        button.classList.remove('loading');

    }


    return (
        <body>
        <section className="section">
            <div className="container">
                <br/>
                <br/>
                <br/>
                <div className="content">
                    <h1> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ⭐ <u> Umee Orchestrator Health Checker </u> ⭐  </h1>

                </div>
            <br/>

                <div className="columns">
                    <div className="column is-three-quarters">
                        <div className="card">
                            <div className="card-content">
                                <form id="loan-form">


                                    <div className="level">
                                        {/*// <!-- Left side -->*/}
                                        <div className="level-left is-marginless">
                                            <div className="level-item">
                                                <h1><b> Orchestrator Address</b></h1>
                                            </div>
                                        </div>

                                        {/*// <!-- Right side -->*/}
                                        <div className="level-right">
                                            <div className="level-item">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" id="address" type="text"/>
                                                        {/*<input className="input" id="address" type="text" onChange={e => loadIndexerData(e.target.value,setIndexerData)} />*/}
                                                        <span className="icon is-small is-left">
                              <i className="fa fa-home"></i>
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="my-button" className="control">
                                        <button id="button"
                                                className="button is-large is-fullwidth is-primary is-outlined"
                                                type={"button"} onClick={load}
                                        >
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/*// <!-- RESULTS -->*/}
        <section className="section">
            <h1 className="title ">Health Results</h1>
            <div className="columns is-multiline">

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-primary has-text">
                        <p id="ownEventNonce" className="title is-1">#️⃣</p>
                        <p className="subtitle is-4">Your Nonce</p>
                    </div>
                </div>

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-info has-text">
                        <p id="lagging_by" className="title is-1">🏃‍</p>
                        <p className="subtitle is-4">Lagging By</p>
                    </div>
                </div>

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-link has-text">
                        <p id="percentageHigherThanYourOwn" className="title is-1">⬆️</p>
                        <p className="subtitle is-4">% Higher Than Your Own</p>
                    </div>
                </div>

            </div>
        </section>



        </body>
    );
}

function returnApp() {
    return (
        <body>
        <section className="section">
            <div className="container">
                <br/>
                <br/>
                <br/>
                <div className="content">
                    <h1> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   ⭐ <u> Umee Orchestrator Health Checker </u> ⭐  </h1>



                </div>
                <br/>

                <div className="columns">
                    <div className="column is-three-quarters">
                        <div className="card">
                            <div className="card-content">
                                <form id="loan-form">


                                    <div className="level">
                                        {/*// <!-- Left side -->*/}
                                        <div className="level-left is-marginless">
                                            <div className="level-item">
                                                <h1><b> Orchestrator Address</b></h1>
                                            </div>
                                        </div>

                                        {/*// <!-- Right side -->*/}
                                        <div className="level-right">
                                            <div className="level-item">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" id="address" type="text"/>
                                                        {/*<input className="input" id="address" type="text" onChange={e => loadIndexerData(e.target.value,setIndexerData)} />*/}
                                                        <span className="icon is-small is-left">
                              <i className="fa fa-home"></i>
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="my-button" className="control">
                                        <button
                                            className="button is-large is-fullwidth is-primary is-outlined"
                                            type={"button"}
                                        >
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/*// <!-- RESULTS -->*/}
        <section className="section">
            <h1 className="title ">Health Results</h1>
            <div className="columns is-multiline">

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-primary has-text">
                        <p id="ownEventNonce" className="title is-1">#️⃣</p>
                        <p className="subtitle is-4">Your Nonce</p>
                    </div>
                </div>

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-info has-text">
                        <p id="lagging_by" className="title is-1">🏃‍</p>
                        <p className="subtitle is-4">Lagging By</p>
                    </div>
                </div>

                <div className="column is-12-tablet is-6-desktop is-3-widescreen">
                    <div className="notification is-link has-text">
                        <p id="percentageHigherThanYourOwn" className="title is-1">⬆️</p>
                        <p className="subtitle is-4">% Higher Than Your Own</p>
                    </div>
                </div>

            </div>
        </section>


        </body>
    );
}

export default App;
